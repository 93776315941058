import React from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Router from '../router';
import HorizonThemeProvider from '@urbanpiper-engineering/horizon/dist/provider';

function App() {
  const queryClient = new QueryClient();

  return (
    <div className="w-screen h-screen overflow-y-auto">
      <QueryClientProvider client={queryClient}>
        <HorizonThemeProvider>
          <Router />
        </HorizonThemeProvider>
        <Toaster
          gutter={20}
          position="top-right"
          toastOptions={{
            duration: 4 * 1000,
            style: {
              color: '#fff',
              padding: '8px',
              fontSize: '14px',
              background: '#222',
              borderRadius: '2px',
            },
          }}
        />
      </QueryClientProvider>
    </div>
  );
}

export default App;
