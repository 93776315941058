const BIZ_DATA = 'biz-data';
const APP_LOGIN = 'app-login';
const USER_DATA = 'user-data';
const INVITE_DATA = 'invite-data';
const ISD_CODE_GEO = 'isd-code-geo';
const REDIRECT_APP = 'redirect-app';
const REDIRECT_HOST = 'redirect-host';
const ACCESS_TOKEN = 'access-token';
const REGISTER_FORM = 'register-form';
const DUPLICATE_DATA = 'duplicate-data';
const FEATURE_GROUP = 'feature-group';
const EDC_DEVICE = 'edc-device';
const EXCLUDE_ACCESS_TOKEN_IN_ROUTES = ['/onboarding/biz-verify/'];

// we're caching local storage data to make sure
// we work correctly within a session even if local storage is
// disabled or not allowed
const CACHE = {};

/**
 * Get data from local storage
 */
function get(key, missing = {}) {
  try {
    if (!window.localStorage) {
      return CACHE[key] || missing;
    }
  } catch (e) {
    console.error('Local storage not supported', e);
    return CACHE[key] || missing;
  }

  let value = localStorage.getItem(key);
  if (value) {
    value = JSON.parse(value);
    CACHE[key] = value;
    return value;
  }

  return missing;
}

/**
 * Set data in local storage
 */
function set(key, data) {
  CACHE[key] = data;

  try {
    if (!window.localStorage) {
      return;
    }
  } catch (e) {
    console.error('Local storage not supported', e);
    return;
  }

  localStorage.setItem(key, JSON.stringify(data));
}

/**
 * Get user details
 */
function getUserDetails() {
  return get(USER_DATA, {});
}

/**
 * Set user details
 */
function setUserDetails(data) {
  set(USER_DATA, data);
}

/**
 * Get user access token
 */
function getAccessToken() {
  if (EXCLUDE_ACCESS_TOKEN_IN_ROUTES.includes(window.location.pathname)) {
    return undefined;
  }
  return get(ACCESS_TOKEN, '');
}

/**
 * Set user access token
 */
function setAccessToken(data) {
  set(ACCESS_TOKEN, data);
}

/**
 * Get duplicate data details
 */
function getDuplicateData() {
  return get(DUPLICATE_DATA, {});
}

/**
 * Set duplicate data details
 */
function setDuplicateData(data) {
  set(DUPLICATE_DATA, data);
}

/**
 * Get business data details
 */
function getBizData() {
  return get(BIZ_DATA, []);
}

/**
 * Set business data details
 */
function setBizData(data) {
  set(BIZ_DATA, data);
}

/**
 * Get whether user is logged in via app
 */
function getAppLogin() {
  return get(APP_LOGIN, false);
}

/**
 * Set whether user is logging in via app
 */
function setAppLogin(data) {
  if (data === 'prime') {
    set(APP_LOGIN, data);
  } else {
    set(APP_LOGIN, false);
  }
}

/**
 * Get invite data details
 */
function getInviteData() {
  return get(INVITE_DATA, []);
}

/**
 * Set invite data details
 */
function setInviteData(data) {
  set(INVITE_DATA, data);
}

/**
 * Get register form details
 */
function getRegisterFormData() {
  return get(REGISTER_FORM, {});
}

/**
 * Set register form details
 */
function setRegisterFormData(data) {
  set(REGISTER_FORM, data);
}

/**
 * Get redirect app
 */
function getRedirectApp() {
  return get(REDIRECT_APP, '');
}

/**
 * Set redirect app
 */
function setRedirectApp(data) {
  set(REDIRECT_APP, data);
}

/**
 * Get redirect host
 */
function getRedirectHost() {
  return get(REDIRECT_HOST, null);
}

/**
 * Set redirect host
 */
function setRedirectHost(data) {
  set(REDIRECT_HOST, data);
}

/**
 * Reset redirect host
 */
function resetRedirectHost() {
  try {
    localStorage.removeItem(REDIRECT_HOST);
  } catch (e) {
    console.error('Error clearing local storage', e);
  }
}

/**
 * Get ISD code as per geolocation
 */
function getIsdCodeGeo() {
  return get(ISD_CODE_GEO, '');
}

/**
 * Set ISD code as per geolocation
 */
function setIsdCodeGeo(data) {
  set(ISD_CODE_GEO, data);
}

/**
 * Get edc device
 */
function getEdcDevice() {
  return get(EDC_DEVICE, 0);
}

/**
 * Set edc device
 */
function setEdcDevice(data) {
  set(EDC_DEVICE, data);
}

/**
 * Clear local storage data on user logout
 */
function clearUserDataOnLogout(clearBiz = true) {
  try {
    if (clearBiz) {
      localStorage.removeItem(BIZ_DATA);
    }

    localStorage.removeItem(USER_DATA);
    localStorage.removeItem(INVITE_DATA);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REGISTER_FORM);
    localStorage.removeItem(DUPLICATE_DATA);
  } catch (e) {
    console.error('Error clearing local storage', e);
  }
}

/**
 * Get feature group for user (a number between 0-100)
 *
 * @return int
 */
function getUserFeatureGroup() {
  let group = get(FEATURE_GROUP, null);
  if (group === null) {
    group = Math.round(Math.random() * 100);
    set(FEATURE_GROUP, group);
  }

  return group;
}

const LocalStorageHelper = {
  getUserDetails,
  setUserDetails,
  getAccessToken,
  setAccessToken,
  getDuplicateData,
  setDuplicateData,
  getBizData,
  setBizData,
  getAppLogin,
  setAppLogin,
  getInviteData,
  setInviteData,
  getRegisterFormData,
  setRegisterFormData,
  getRedirectApp,
  setRedirectApp,
  getIsdCodeGeo,
  setIsdCodeGeo,
  getEdcDevice,
  setEdcDevice,
  clearUserDataOnLogout,
  getUserFeatureGroup,
  getRedirectHost,
  setRedirectHost,
  resetRedirectHost,
};

export default LocalStorageHelper;
