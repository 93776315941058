import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import HelpText from './HelpText';
import CardHeader from '../../components/CardHeader';
import UpInput from '../../components/Common/UpInput';
import UpButton from '../../components/Common/UpButton';
import MagicWandIcon from '../../assets/icons/MagicWandIcon';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import {
  loginOtpInitiate,
  loginOtpVerify,
  redirectToPlatform,
  verifyUserToken,
} from '../../api/auth';
import { checkErrorTypeString } from '../../helpers/utils';
import UpToast from '../../components/Common/UpToast';
import {
  getRedirectRoute,
  redirectUserToPlatform,
} from '../../helpers/bizDataHelpers';
import PathStatus from '../../constants/PathStatus';
import AnalyticsService from '../../services/analytics';
import { getRecaptchaSiteKey } from '../../helpers/environment.js';
import Vent from '../../vent';
import CaptchaHelper from "../../helpers/CaptchaHelper.js";

function OtpView() {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [otp, setOtp] = useState('');
  const [inputError, setInputError] = useState('');
  const [resendCount, setResendCount] = useState(30);
  const [numOfTimesResendClicked, setNumOfTimesResendClicked] = useState(0);
  const [startCounter, setStartCounter] = useState(true);
  const isAppLogin = LocalStorageHelper.getAppLogin();
  const { selectedLogin, isMobile, emailMobile, countryCode } =
    LocalStorageHelper.getUserDetails();

  const { isLoading: redirectLoading, mutate: callRedirectPlatform } =
    useMutation({
      mutationFn: ({ appType, params }) => redirectToPlatform(appType, params),
      onSuccess: redirectUserToPlatform,
      onError: error => {
        const errorMessage = checkErrorTypeString(
          error?.response?.data?.message,
        );
        UpToast.error(errorMessage);
      },
    });

  useEffect(() => {
    if (startCounter && resendCount > 0) {
      setTimeout(() => setResendCount(resendCount - 1), 1000);
    }

    if (resendCount === 0) {
      setStartCounter(false);
      setResendCount(30);
    }
  }, [resendCount, startCounter]);

  const { isLoading: initiateLoading, mutate: callOtpInitiate } = useMutation({
    mutationFn: ({ authType, loginSource, params }) =>
      loginOtpInitiate(authType, loginSource, params),
    onError: onOtpInitiateError,
    onSuccess: onOtpInitiateSuccess,
  });

  useEffect(() => {
    if (searchParams.get('token')) {
      LocalStorageHelper.setAccessToken(searchParams.get('token'));
      callVerifyUserToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: tokenLoading, mutate: callVerifyUserToken } = useMutation({
    mutationFn: verifyUserToken,
    onError: onVerifyUserError,
    onSuccess: onLoginSuccess,
  });

  function onVerifyUserError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);
    UpToast.error(errorMessage);
    navigate('/logout');
  }

  function onOtpInitiateSuccess(resp) {
    setOtp('');
    setInputError('');
    setStartCounter(true);
    UpToast.success('OTP sent successfully.');

    if (resp?.token) {
      LocalStorageHelper.setAccessToken(resp?.token);
    }
  }

  function onOtpInitiateError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);
    UpToast.error(errorMessage);
  }

  const { isLoading: verifyLoading, mutate: callOtpVerify } = useMutation({
    mutationFn: ({ authType, loginSource, params }) =>
      loginOtpVerify(authType, loginSource, params),
    onSuccess: onLoginSuccess,
    onError: err => {
      const errorMessage = checkErrorTypeString(err?.response?.data?.message);

      if (err?.response?.data?.err_text === 'INVALID_RECAPTCHA') {
        UpToast.error(errorMessage);
      } else {
        setInputError(errorMessage);
      }
    },
  });

  function onLoginSuccess(resp) {
    Vent.onUserIdentified();
    AnalyticsService.onUleLogin({
      field: isMobile ? "phone" : "email",
      otp: true,
      [isMobile ? "phone" : "email"]: emailMobile || '',
      isd_code: countryCode,
    });

    const redirect = getRedirectRoute(resp);
    const allBizData = LocalStorageHelper.getBizData();
    const redirectHost = LocalStorageHelper.getRedirectHost();

    if (
      allBizData?.length === 1 &&
      allBizData?.[0]?.appsbiz?.length === 1 &&
      resp?.path_status === PathStatus.APP_BIZ_SELECTION
    ) {
      const bizData = allBizData[0];
      callRedirectPlatform({
        appType: bizData?.appsbiz?.[0]?.app_name,
        params: {
          biz_id: bizData?.biz_id,
          app_name: bizData?.appsbiz?.[0]?.app_name,
          app_biz_id: bizData?.appsbiz?.[0]?.app_biz_id,
          redirect_host: redirectHost,
        },
      });
      return;
    }

    if (redirect) {
      navigate(redirect);
    }
  }

  function onOtpChange(updatedValue) {
    setInputError('');
    setOtp(updatedValue);
  }

  async function onResendOtp() {
    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    const params = {
      value: emailMobile,
      type: isMobile ? 'phone' : 'email',
      isd_code: isMobile ? countryCode : '',
      re_captcha: captchaToken,
    };

    // AnalyticsService.onResendOtpButton(
    //   'otp-login',
    //   isMobile ? 'phone' : 'email',
    // );

    callOtpInitiate({
      params,
      authType: 'otp',
      loginSource: isMobile ? 'phone' : 'email',
    });
    setNumOfTimesResendClicked(numOfTimesResendClicked + 1);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!otp) {
      return;
    }

    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    const params = {
      otp,
      value: emailMobile,
      type: isMobile ? 'phone' : 'email',
      isd_code: isMobile ? countryCode : '',
      re_captcha: captchaToken,
    };

    callOtpVerify({
      params,
      authType: 'otp',
      loginSource: isMobile ? 'phone' : 'email',
    });
  }

  const viewLoading =
    initiateLoading || verifyLoading || tokenLoading || redirectLoading;

  return (
    <div>
      <UpLoadingBar loading={viewLoading} />
      <div className="dark-view-wrapper relative">
        <UpLoadingCover loading={viewLoading} />
        <div className="flex-grow">
          <CardHeader
            title="Login"
            subtitle={
              <div className="flex items-center">
                {selectedLogin && (
                  <span className="mr-1">
                    <span className="font-normal">OTP sent to </span>
                    <span>{selectedLogin}</span>
                  </span>
                )}
                <UpButton
                  variant="link"
                  loading={viewLoading}
                  onClick={() => navigate('/login/email-mobile')}>
                  Change
                </UpButton>
              </div>
            }
          />
          <form onSubmit={handleSubmit}>
            <UpInput
              autoFocus
              loading={viewLoading}
              type="number"
              name="otp"
              label="Enter OTP"
              placeholder="xxx-xxx"
              wrapperClass="mt-12 mb-8"
              value={otp}
              onChange={onOtpChange}
              errorContent={inputError}
              extraAction={
                startCounter ? (
                  <div className="text-up-clr-light-grey font-semibold text-sm text-right">
                    Resend OTP in 00:{('0' + resendCount).slice(-2)}
                  </div>
                ) : (
                  <UpButton
                    loading={viewLoading}
                    variant="link"
                    onClick={onResendOtp}
                    wrapperClass="w-max ml-auto">
                    Resend OTP
                  </UpButton>
                )
              }
            />
            <UpButton type="submit" wrapperClass="mb-5" loading={viewLoading}>
              Login
            </UpButton>
          </form>
          <UpButton
            variant="link"
            loading={viewLoading}
            onClick={() => navigate('/login/password')}>
            Login with Password
          </UpButton>
          {!isMobile && !isAppLogin && (
            <div className="bg-up-clr-lightest-grey p-3 flex rounded-sm mt-9">
              <MagicWandIcon />
              <div className="text-up-clr-black text-sm ml-2">
                You can also use the magic link provided in the OTP email to
                login directly
              </div>
            </div>
          )}
        </div>
        {numOfTimesResendClicked >= 2 ? <HelpText screen="otp-login" prefilled={{ loginCred: emailMobile }} /> : null}
      </div>

      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        badge="bottomleft"
        sitekey={getRecaptchaSiteKey()}
      />
    </div>
  );
}

export default OtpView;
