import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import CardHeader from '../../components/CardHeader';
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';
import { redirectToPlatform } from '../../api/auth';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import {
  getAvailablePlatforms,
  redirectUserToPlatform,
} from '../../helpers/bizDataHelpers';
import { checkErrorTypeString } from '../../helpers/utils';
import UpToast from '../../components/Common/UpToast';
import LocalStorageHelper from '../../helpers/localStorageHelpers';

function SelectPlatform() {
  const { bizId } = useParams();
  const navigate = useNavigate();
  const { selectedBiz, platforms } = getAvailablePlatforms(bizId);
  const redirectHost = LocalStorageHelper.getRedirectHost();

  const { isLoading, mutate: callRedirectPlatform } = useMutation({
    mutationFn: ({ appType, params }) => redirectToPlatform(appType, params),
    onSuccess: redirectUserToPlatform,
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
    },
  });

  function redirectToBizPlatform(platform) {
    if (platform?.multiBiz) {
      navigate(`/business/${bizId}/${platform?.id}`);
      return;
    }

    callRedirectPlatform({
      appType: platform?.id,
      params: {
        biz_id: Number(bizId),
        app_name: platform?.id,
        app_biz_id: platform?.bizId,
        redirect_host: redirectHost,
      },
    });
  }

  if (!selectedBiz || platforms?.length === 0) {
    return <Navigate to={'/business'} />;
  }

  return (
    <div>
      <UpLoadingBar loading={isLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={isLoading} />
        <p
          onClick={() => navigate('/business')}
          className="text-sm text-up-clr-light-grey mb-1 flex items-center cursor-pointer">
          <span className="mr-1 capitalize">{selectedBiz}</span>
          <RightArrowIcon />
        </p>
        <CardHeader title="Select an app" wrapperClass="pb-6" />
        {platforms?.map((platform, idx, pArr) => {
          if (!platform?.bizId) {
            return null;
          }

          return (
            <div key={platform?.id}>
              <PlatformCard
                platform={platform}
                redirectToBizPlatform={redirectToBizPlatform}
              />
              {idx < pArr.length - 1 && (
                <div className="border-t border-up-clr-neutral-grey md:-mx-8" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function PlatformCard({ platform, redirectToBizPlatform }) {
  return (
    <div
      onClick={() => redirectToBizPlatform(platform)}
      className="my-6 flex items-center cursor-pointer transition-colors duration-500 text-up-clr-neutral-grey hover:text-up-clr-tinted-grey">
      <div className="w-12 h-12 flex-shrink-0 self-start">
        <img
          src={platform.logo}
          alt={platform.name}
          className="w-full h-full object-contain"
        />
      </div>
      <div style={{ maxWidth: 300 }} className="ml-4 mr-auto">
        <p className="text-lg font-semibold text-up-clr-black leading-6">
          {platform.name}
        </p>
        <p className="text-sm text-up-clr-dark-grey">{platform.desc}</p>
      </div>
      <div className="p-2 select-none">
        <ArrowRightIcon />
      </div>
    </div>
  );
}

export default SelectPlatform;
