import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { redirectToPlatform } from '../../api/auth';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import PlaceholderBiz from '../../assets/images/placeholder-biz.svg';
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';
import {
  getLinkedBrands,
  redirectUserToPlatform,
} from '../../helpers/bizDataHelpers';
import CardHeader from '../../components/CardHeader';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';
import { checkErrorTypeString } from '../../helpers/utils';
import UpToast from '../../components/Common/UpToast';
import LocalStorageHelper from '../../helpers/localStorageHelpers';

function SelectBrand() {
  const navigate = useNavigate();
  const { bizId, platform } = useParams();
  const brandList = getLinkedBrands(bizId, platform);
  const redirectHost = LocalStorageHelper.getRedirectHost();

  const { isLoading, mutate: callRedirectPlatform } = useMutation({
    mutationFn: ({ appType, params }) => redirectToPlatform(appType, params),
    onSuccess: redirectUserToPlatform,
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
    },
  });

  function redirectToBizPlatform(brand) {
    callRedirectPlatform({
      appType: platform,
      params: {
        app_name: platform,
        biz_id: Number(bizId),
        app_biz_id: brand?.app_biz_id,
        redirect_host: redirectHost,
      },
    });
  }

  if (!brandList || brandList?.length === 0) {
    return <Navigate to={'/business'} />;
  }

  return (
    <div>
      <UpLoadingBar loading={isLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={isLoading} />
        <p
          onClick={() => navigate(`/business/${bizId}`)}
          className="text-sm text-up-clr-light-grey mb-1 flex items-center cursor-pointer">
          <span className="mr-1 capitalize">{platform}</span>
          <RightArrowIcon />
        </p>
        <CardHeader title="Select a Prime company" wrapperClass="pb-6" />
        {!!brandList?.length && (
          <p className="text-sm text-up-clr-light-grey py-2">
            Your companies ({brandList?.length})
          </p>
        )}
        {brandList?.map((brand, idx) => {
          return (
            <div key={brand?.app_biz_id}>
              <BrandCard
                brand={brand}
                redirectToBizPlatform={redirectToBizPlatform}
              />
              {idx < brandList?.length - 1 && (
                <div className="border-t border-up-clr-neutral-grey md:-mx-8" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function BrandCard({ brand, redirectToBizPlatform }) {
  return (
    <div
      onClick={() => redirectToBizPlatform(brand)}
      className="my-6 flex items-center cursor-pointer transition-colors duration-500 text-up-clr-neutral-grey hover:text-up-clr-tinted-grey">
      <div className="w-12 h-12 flex-shrink-0 self-start">
        <img
          src={brand?.app_biz_logo || PlaceholderBiz}
          alt={brand?.app_biz_name}
          className="w-full h-full object-contain"
        />
      </div>
      <div style={{ maxWidth: 300 }} className="ml-4 mr-auto">
        <p className="text-lg font-semibold text-up-clr-black leading-6">
          {brand?.app_biz_name}
        </p>
      </div>
      <div className="p-2 select-none">
        <ArrowRightIcon />
      </div>
    </div>
  );
}

export default SelectBrand;
