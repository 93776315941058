import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation } from '@tanstack/react-query';
import HelpText from './HelpText';
import {
  loginUser,
  resetPassword,
  loginOtpInitiate,
  redirectToPlatform,
} from '../../api/auth';
import CardHeader from '../../components/CardHeader';
import UpInput from '../../components/Common/UpInput';
import UpButton from '../../components/Common/UpButton';
import { checkErrorTypeString } from '../../helpers/utils';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import UpToast from '../../components/Common/UpToast';
import {
  getRedirectRoute,
  redirectUserToPlatform,
} from '../../helpers/bizDataHelpers';
import PathStatus from '../../constants/PathStatus';
import { getRecaptchaSiteKey } from '../../helpers/environment.js';
import AnalyticsService from "../../services/analytics.js";
import Vent from '../../vent';
import CaptchaHelper from '../../helpers/CaptchaHelper.js';

function PasswordView() {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const { isMobile, selectedLogin, countryCode, emailMobile } =
    LocalStorageHelper.getUserDetails();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const isEdcDevice = LocalStorageHelper.getEdcDevice();

  const { isLoading: redirectLoading, mutate: callRedirectPlatform } =
    useMutation({
      mutationFn: ({ appType, params }) => redirectToPlatform(appType, params),
      onSuccess: redirectUserToPlatform,
      onError: error => {
        const errorMessage = checkErrorTypeString(
          error?.response?.data?.message,
        );
        UpToast.error(errorMessage);
      },
    });

  const { isLoading: loginLoading, mutate: callLoginUser } = useMutation({
    mutationFn: loginUser,
    onError: onLoginError,
    onSuccess: onLoginSuccess,
  });

  const { isLoading: otpLoading, mutate: callLoginOtpInitiate } = useMutation({
    mutationFn: ({ authType, loginSource, params }) =>
      loginOtpInitiate(authType, loginSource, params),
    onError: onLoginOtpError,
    onSuccess: onLoginOtpSuccess,
  });

  function onLoginOtpSuccess(resp) {
    if (resp?.token) {
      LocalStorageHelper.setAccessToken(resp?.token);
    }

    navigate('/login/otp');
  }

  function onLoginOtpError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);

    UpToast.error(errorMessage);
  }

  const { isLoading: resetLoading, mutate: callResetPassword } = useMutation({
    mutationFn: ({ loginSource, params }) => resetPassword(loginSource, params),
    onError: onResetPasswordError,
    onSuccess: onResetPasswordSuccess,
  });

  function onLoginSuccess(resp) {
    Vent.onUserIdentified();
    AnalyticsService.onUleLogin({
      field: isMobile ? "phone" : "email",
      pwd: true,
      [isMobile ? "phone" : "email"]: emailMobile || '',
      isd_code: countryCode,
    });

    const redirect = getRedirectRoute(resp);
    const allBizData = LocalStorageHelper.getBizData();
    const redirectHost = LocalStorageHelper.getRedirectHost();

    if (
      allBizData?.length === 1 &&
      allBizData?.[0]?.appsbiz?.length === 1 &&
      resp?.path_status === PathStatus.APP_BIZ_SELECTION
    ) {
      const bizData = allBizData[0];
      callRedirectPlatform({
        appType: bizData?.appsbiz?.[0]?.app_name,
        params: {
          biz_id: bizData?.biz_id,
          app_name: bizData?.appsbiz?.[0]?.app_name,
          app_biz_id: bizData?.appsbiz?.[0]?.app_biz_id,
          redirect_host: redirectHost,
        },
      });
      return;
    }

    if (redirect) {
      navigate(redirect);
    }
  }

  function onLoginError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);

    if (err?.response?.data?.err_text === 'INVALID_RECAPTCHA') {
      UpToast.error(errorMessage);
    } else {
      setPasswordError(errorMessage);
    }
  }

  function onResetPasswordSuccess(resp) {
    if (resp?.access_token) {
      LocalStorageHelper.setAccessToken(resp?.access_token);
    }

    navigate('/forgot-password/verify');
  }

  function onResetPasswordError(err) {
    const errorMessage = checkErrorTypeString(err?.response?.data?.message);

    UpToast.error(errorMessage);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (loginLoading || resetLoading || otpLoading) {
      return;
    }

    if (!password || password.length === 0) {
      setPasswordError('Password cannot be blank');
      return;
    }

    const params = {
      password,
    };

    if (isMobile) {
      params.phone = emailMobile;
      params.isd_code = countryCode;
    } else {
      params.email = emailMobile;
    }

    callLoginUser(params);
  }

  function onPasswordChange(updatedValue) {
    setPasswordError('');
    setPassword(updatedValue);
  }

  async function onForgotPasswordClick() {
    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    callResetPassword({
      loginSource: isMobile ? 'phone' : 'email',
      params: {
        value: emailMobile,
        type: isMobile ? 'phone' : 'email',
        isd_code: isMobile ? countryCode : '',
        re_captcha: captchaToken,
      },
    });
  }

  async function onLoginOtpInitiate() {
    const captchaToken = await CaptchaHelper.getToken(captchaRef);
    if (!captchaToken) {
      return;
    }

    const params = {
      value: emailMobile,
      type: isMobile ? 'phone' : 'email',
      isd_code: isMobile ? countryCode : '',
      re_captcha: captchaToken,
    };

    callLoginOtpInitiate({
      params,
      authType: 'otp',
      loginSource: isMobile ? 'phone' : 'email',
    });
  }

  return (
    <div>
      <UpLoadingBar
        loading={loginLoading || resetLoading || otpLoading || redirectLoading}
      />
      <div className="dark-view-wrapper relative">
        <UpLoadingCover
          loading={
            loginLoading || resetLoading || otpLoading || redirectLoading
          }
        />
        <div className="flex-grow">
          <CardHeader
            title="Login"
            subtitle={
              <div className="flex items-center">
                {selectedLogin && <span className="mr-1">{selectedLogin}</span>}
                <UpButton
                  variant="link"
                  loading={loginLoading || resetLoading || otpLoading}
                  onClick={() => navigate('/login/email-mobile')}>
                  Change
                </UpButton>
              </div>
            }
          />
          <form onSubmit={handleSubmit}>
            <UpInput
              autoFocus
              type="password"
              name="password"
              label="Password"
              placeholder="Password"
              wrapperClass="mt-12 mb-8"
              value={password}
              loading={loginLoading || resetLoading || otpLoading}
              onChange={onPasswordChange}
              errorContent={passwordError}
              extraAction={
                !isEdcDevice && (
                  <UpButton
                    variant="link"
                    wrapperClass="w-max ml-auto"
                    onClick={onForgotPasswordClick}>
                    Forgot Password
                  </UpButton>
                )
              }
            />
            <UpButton
              type="submit"
              loading={loginLoading || resetLoading || otpLoading}
              wrapperClass="mb-5">
              Login
            </UpButton>
          </form>
          {!isEdcDevice && (
            <UpButton
              variant="link"
              loading={loginLoading || resetLoading || otpLoading}
              onClick={onLoginOtpInitiate}>
              Get OTP on {isMobile ? 'Mobile' : 'Mail'}
            </UpButton>
          )}
        </div>
        <HelpText screen="password" prefilled={{ loginCred: emailMobile }} />
      </div>

      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        badge="bottomleft"
        sitekey={getRecaptchaSiteKey()}
      />
    </div>
  );
}

export default PasswordView;
