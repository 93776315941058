import React, { useEffect } from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import UpVioletImg from '../../assets/images/up-violet.svg';
import OmkVioletImg from '../../assets/images/omk-violet.svg';
import SantaHat from '../../assets/images/icon-santa-hat.svg';
import UpButton from '../Common/UpButton';
import { OMK_COUNTRIES } from "../../helpers/bizDataHelpers";
import "./style.scss"
import { isChristmasSeason } from '../../helpers/utils';

function TopBar({ showLogout = true, isSticky = false, showContentRight = false, renderContentRight }) {
  const navigate = useNavigate();
  const countryOrigin = window?.GEO_DATA?.country || "IN";

  function getLogoStyles() {
    if (OMK_COUNTRIES.includes(countryOrigin)) {
      return {
        position: "relative",
        width: 245,
        minHeight: 24
      };
    }
    return {
      position: "relative",
      width: 115,
      minHeight: 24
    };
  }

  return (
    <div
      className={cx(
        'top-header bg-white py-5 px-4 md:px-5 flex justify-between items-center top-0 z-50',
        showLogout || isSticky ? 'sticky' : 'sticky lg:hidden',
      )}>
      <div style={getLogoStyles()}>
        <img
          src={
            OMK_COUNTRIES.includes(countryOrigin) ? OmkVioletImg : UpVioletImg
          }
          alt="urbanpiper"
          className="w-full h-full object-contain"
        />
        {!OMK_COUNTRIES.includes(countryOrigin) && isChristmasSeason() && (
          <img className="santa-hat-up" src={SantaHat} />
        )}
      </div>
      {showLogout && (
        <UpButton
          variant="link"
          wrapperClass="w-max ml-auto"
          onClick={() => navigate('/logout')}>
          Logout
        </UpButton>
      )}
      {showContentRight && (
        <div className="content-right">{renderContentRight}</div>
      )}
    </div>
  );
}

export default TopBar;
